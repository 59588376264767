var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "800px", "no-click-animation": "" },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _vm.tripRequest.id
            ? _c("v-card-title", [
                _vm._v(
                  "Change Trip Request #" +
                    _vm._s(_vm.tripRequest.id) +
                    " Submitter"
                ),
              ])
            : _c("v-card-title", [_vm._v("Change Trip Requests' Submitter")]),
          _c(
            "v-card-text",
            { staticClass: "pt-5" },
            [
              _c(
                "v-row",
                [
                  !_vm.tripRequest.id
                    ? _c(
                        "v-col",
                        { attrs: { cols: "12", md: "12" } },
                        [
                          _c("v-select", {
                            attrs: {
                              label: "Old Submitter",
                              items: _vm.submitters,
                              "item-text": _vm.getUserText,
                              "item-value": "id",
                              outlined: "",
                              "return-object": "",
                            },
                            model: {
                              value: _vm.oldSubmitter,
                              callback: function ($$v) {
                                _vm.oldSubmitter = $$v
                              },
                              expression: "oldSubmitter",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "12" } },
                    [
                      _c("v-select", {
                        attrs: {
                          label: "New Submitter",
                          items: _vm.users,
                          "item-text": _vm.getUserText,
                          "item-value": "id",
                          outlined: "",
                          "return-object": "",
                        },
                        model: {
                          value: _vm.newSubmitter,
                          callback: function ($$v) {
                            _vm.newSubmitter = $$v
                          },
                          expression: "newSubmitter",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", text: "" },
                  on: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [_vm._v(" Close ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "success", dark: "" },
                  on: { click: _vm.saveChange },
                },
                [_vm._v(" Save ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }